<template>
    <el-row class="row" :gutter="20" v-loading="loadinga">
        <el-col :sm="24" :md="16">
            <el-card class="card-left-top">
                <div slot="header" class="clearfix">
                    <span>感谢您使用ChmlFrp</span>
                </div>
                <div class="bolder">
                    <p>当前面板版本：2.3.1</p>
                    <span>Copyright © 2021 - 2025 TechCat All rights reserved.</span>
                </div>
                <span>ChmlFrp由怊猫科技运营&维护，ChmlFrp使用的全部软件均为原创&二次开发</span>
                <el-divider></el-divider>
                <el-link type="primary" class="fa" href="https://docs.chcat.cn">ChmlFrp文档</el-link>
            </el-card>
        </el-col>
        <el-col :sm="24" :md="8">
            <el-card class="card-right">
                <div slot="header" class="clearfix">
                    <span>其他</span>
                </div>
                <span>此版本已结束生命周期，v2版本永久不再更新。敬请期待ChmlFrp V3。(V3版本更新后，旧版的账户、会员任然保留用于v3登录)</span>
            </el-card>
        </el-col>
        <!-- <el-col>
        <el-card class="card-left-bottom">

        </el-card>
    </el-col> -->
    </el-row>
</template>

<script>
import { setToken, getToken, removeToken } from '@/utils/setToken.js';
import axios from 'axios';

export default {
    data() {
        return {
            loadinga: false,
        }
    },
    created() {
        const usertoken = getToken('token');

        if (usertoken) {
            this.setData();
        } else {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
                message: "无效的token，请重新登录",
                type: "error",
            });
        }
    },
    methods: {
        setData(params) {
            this.loadinga = true;
            const usertoken = getToken('token');
            axios
                .get('https://cf-v1.uapis.cn/api/userinfo.php', {
                    params: { usertoken, ...params },
                })
                .then(response => {
                    if (response.data.error) {
                        removeToken('token');
                        this.$router.push('/login');
                        this.$message({
                            message: "无效的token，请重新登录",
                            type: "error",
                        });
                    } else {
                        const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

                        setToken('userid', userid);
                        setToken('username', username);
                        setToken('email', email);
                        setToken('qq', qq);
                        setToken('usergroup', usergroup);
                        setToken('bandwidth', bandwidth);
                        setToken('tunnel', tunnel);
                        setToken('realname', realname);
                        setToken('integral', integral);
                        setToken('userimg', userimg);
                        setToken('term', term);
                        setToken('tunnelstate', tunnelstate);
                    }
                })
                .catch(error => {
                    console.error('请求API时发生错误:', error);
                })
                .finally(() => {
                    this.loadinga = false;
                })
        }
    }
}
</script>

<style lang="less" scoped>
.bolder {
    font-weight: bolder;
}

.fa {
    font-size: 25px;
}

.row {
    min-height: 340px;

    .card-left-top {
        min-height: 340px;
    }

    .card-left-bottom {
        min-height: 200px;
        margin-top: 16px;
    }
}
</style>